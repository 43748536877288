import GATSBY_COMPILED_MDX from "/opt/build/repo/src/posts/how-much-does-it-cost-to-paint-cabinets/how-much-does-it-cost-to-paint-cabinets.mdx";
import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout.js";
import {MDXProvider} from "@mdx-js/react";
import * as BlogTemplateStyles from "../styles/blogTemplate.module.scss";
import SEO from "../components/seo";
function Template({data, children}) {
  const {mdx, body} = data;
  const {frontmatter} = mdx;
  const shortcodes = {
    Link
  };
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: frontmatter.title,
    image: data.featuredImage.secure_url,
    dateModified: frontmatter.dateModified,
    datePublished: frontmatter.date,
    pathname: frontmatter.path,
    description: frontmatter.description,
    isBlogPost: true
  }), React.createElement("div", {
    className: BlogTemplateStyles.container
  }, React.createElement("div", {
    className: BlogTemplateStyles.post
  }, React.createElement("h1", {
    className: BlogTemplateStyles.postTitle
  }, frontmatter.title), React.createElement("h4", {
    className: BlogTemplateStyles.date
  }, frontmatter.date), React.createElement("img", {
    alt: frontmatter.title,
    className: BlogTemplateStyles.featuredImage,
    src: data.featuredImage.secure_url
  }), React.createElement("div", {
    className: BlogTemplateStyles.blogPostContent
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query($path: String!, $featuredImage: String!, $otherImages: [String]) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        dateModified
        author
      }
      body
    }
    featuredImage: cloudinaryMedia(public_id: { eq: $featuredImage }) {
      secure_url
    }
    otherImages: allCloudinaryMedia(
      filter: { public_id: { in: $otherImages } }
    ) {
      edges {
        node {
          secure_url
          public_id
        }
      }
    }
  }
`;
