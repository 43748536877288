/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import OptInForm from "../../components/optInForm";
import * as BlogTemplateStyles from "../../styles/blogTemplate.module.scss";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Getting your cabinets painted professionally can completely change the look of your kitchen or any other room and can be much more cost-effective than replacing your cabinets. When done professionally, your cabinets will come out looking brand new and have a long-lasting, durable finish that will last for years to come. ", React.createElement(_components.strong, null, "The average cost to paint cabinets is around $3,200-$3,500 for a mid-sized kitchen.")), "\n", React.createElement(_components.p, null, "Having your cabinets painted improperly can result in major damage and paint peeling that can end up costing you more to repaint them than if you had a professional do it right the first time. Below are some variables that can affect the cost to paint cabinets."), "\n", React.createElement(_components.p, null, "Want to know the price to paint your cabinets today? Snap some photos of your cabinets and click the button below to get a free quote!\n", React.createElement(Link, {
    to: "/cabinet-virtual-quote/",
    className: BlogTemplateStyles.ctaButton
  }, "Get A Free Virtual Quote")), "\n", React.createElement("p", {
    className: BlogTemplateStyles.sectionHeader
  }, React.createElement("i", {
    className: "fas fa-brush",
    style: {
      color: '#ca0101'
    }
  }), " Number of Cabinet Doors and Drawers"), "\n", React.createElement(_components.p, null, "This is the biggest factor in determining the average cost to paint kitchen cabinets as it influences the amount of time, materials, and labor that will need to be put in to paint the cabinets."), "\n", React.createElement(_components.p, null, "The average kitchen usually has ", React.createElement(_components.strong, null, "about 20 cabinet doors and around 10 cabinet drawers"), " but this can vary greatly depending on the layout of your kitchen."), "\n", React.createElement("p", {
    className: BlogTemplateStyles.sectionHeader
  }, React.createElement("i", {
    className: "fas fa-brush",
    style: {
      color: '#ca0101'
    }
  }), " Condition of Cabinets"), "\n", React.createElement(_components.p, null, "Being one of the most used areas in a home, kitchen cabinets open themselves up to a lot of wear and tear and are vulnerable to being damaged."), "\n", React.createElement(_components.p, null, "Chips and cracks can not only be an eye sore but they can also lead to further, more serious damage which is why it's important to repair them as quickly as possible by patching or filling them in."), "\n", React.createElement(_components.p, null, "Sometimes, the paint used on the cabinets may be peeling or was improperly applied previously in which case the cabinets will need to be stripped of the paint and require more effort than what is standard."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "The more damaged the cabinets are and the amount of work that will be required to repair them will have a direct relation to the cabinet painting cost.")), "\n", React.createElement("p", {
    className: BlogTemplateStyles.sectionHeader
  }, React.createElement("i", {
    className: "fas fa-brush",
    style: {
      color: '#ca0101'
    }
  }), " Cabinet Hardware"), "\n", React.createElement(_components.p, null, "In a lot of cases, those that are looking to paint their cabinets also want to change the hardware they have installed on their cabinets. This includes the ", React.createElement(_components.em, null, "pulls, handles, and knobs"), " used for opening the cabinet doors and drawers."), "\n", React.createElement(_components.p, null, "This can be a simple process of simply removing the old hardware and replacing with new which will not change the cost of painting kitchen cabinets as it is standard operations to remove the old hardware in order to paint the doors and drawers. However, there are times when you want to install different-sized pulls or would like to position the knobs on the cabinet drawers just a little bit lower than where they are now; this requires the need to fill in and patch the holes of the old hardware and to drill in the holes for new hardware which can alter the price of your cabinet painting project."), "\n", React.createElement("p", {
    className: BlogTemplateStyles.sectionHeader
  }, React.createElement("i", {
    className: "fas fa-brush",
    style: {
      color: '#ca0101'
    }
  }), " Color Choices"), "\n", React.createElement(_components.p, null, "Wanting to change the color of cabinets is the most common reason homeowners decide to have their cabinets painted behind the need to just refresh the cabinets with their current colors."), "\n", React.createElement(_components.p, null, "There are a lot of considerations to make and color schemes to think about when choosing a color to paint your kitchen cabinets so many people head to the internet and social media for ideas and inspiration."), "\n", React.createElement(_components.p, null, "Most times, people choose to paint all of the cabinets one single color but some prefer to have multiple colors where maybe the upper row of cabinets are painted one color and the lower row of cabinets painted another. Maybe you want your island to be painted a different color than the rest of your kitchen cabinets. These types of designs can look beautiful and offer color contrast that painting one color might not but ", React.createElement(_components.strong, null, "the number of colors you choose will also reflect in the cost to paint cabinets.")), "\n", React.createElement("img", {
    src: props.data.otherImages.edges[0].node.secure_url,
    className: BlogTemplateStyles.postImage
  }), "\n", React.createElement("p", {
    className: BlogTemplateStyles.sectionHeader
  }, React.createElement("i", {
    className: "fas fa-brush",
    style: {
      color: '#ca0101'
    }
  }), " Any Glass Insets"), "\n", React.createElement(_components.p, null, "There are many different styles and designs of cabinet doors and it is common for a kitchen to have a door or two that has a glass inset or glass panes inside the cabinet door frame. Removing paint from glass is not easy and can oftentimes not be removed especially if the coatings used to paint the cabinets is of a high grade."), "\n", React.createElement(_components.p, null, "When protected and treated right, a cabinet door with glass insets can come out look amazing but for that to happen, the cabinet painter must do an excellent job of protecting the glass surfaces so that no paint or damage can be done to the glass. ", React.createElement(_components.strong, null, "This can be a tedious aspect of the preparation process for painting cabinets which is why it can affect on the cost to paint cabinets.")), "\n", React.createElement(_components.p, null, React.createElement("p", {
    className: BlogTemplateStyles.sectionHeader
  }, React.createElement("i", {
    className: "fas fa-paint-roller",
    style: {
      color: '#ca0101'
    }
  }), " Summary"), "\nIn summary, there are a lot of factors that can go into crafting a price to paint cabinets but ", React.createElement(_components.strong, null, "the main variables that can affect that cost are the number of doors and drawers, if there is any damage that needs to be repaired, if the hardware will be changed to a different size, how many colors they will be painted, and if there are any glass insets that need to be protected.")), "\n", React.createElement(_components.p, null, "Not every cabinet painting company will charge in the same way but this is a pretty common way that most professional cabinet painters will use to determine the cost of painting kitchen cabinets."), "\n", React.createElement(OptInForm, {
    isCabinetVirtualForm: true,
    className: BlogTemplateStyles.optInForm
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
